* {
	padding: 0;
	margin: 0;
	border: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	display: block;
	text-decoration: none;
}

a:focus {
	outline: none;
}

a:active {
	outline: none;
}

a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

nav {
	display: block;
}

aside {
	display: block;
}

html {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

:focus-visible {
	outline: none;
}

button,
input,
optgroup,
select,
textarea,
html input[type="button"],
input[type="reset"],
input[type="submit"],
button[disabled],
html input[disabled],
button::-moz-focus-inner,
input::-moz-focus-inner,
input:focus,
input:focus-visible,
input[type="checkbox"],
input[type="radio"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	outline: none;
}

input {
	font-family: inherit;
}

input::-ms-clear {
	display: none;
}

button {
	font-family: inherit;
	cursor: pointer;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

textarea {
	font-family: inherit;
}

ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

h1 {
	font-size: inherit;
	font-weight: 400;
}

h2 {
	font-size: inherit;
	font-weight: 400;
}

h3 {
	font-size: inherit;
	font-weight: 400;
}

h4 {
	font-size: inherit;
	font-weight: 400;
}

h5 {
	font-size: inherit;
	font-weight: 400;
}

h6 {
	font-size: inherit;
	font-weight: 400;
}
