// starting style zeroing
@import "normalize.scss";

// import fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

// colors vars
// colors vars
$black: #1c1c28;
$black1: #121212;

$blue: #598cff;
$blue1: #3e7bfa;
$blue2: #dbe8ff;
$blue3: #22348f;
$blue4: #28293d;
$blue5: #363d48;
$blue6: #007aff;
$blue7: #e7e8f1;
$blue8: #38a0ff;
$blue9: #e5f0ff;
$blue10: #6698fa;
$blue11: #f4f9ff;

$red: #eb3800;
$red1: #ff5c5c;
$red2: #e63535;

$orange: #f9690e;
$orange1: #ff8800;
$orange2: #faede5;

$green: #0da06a;
$green1: #e7f5f0;
$green2: #05a660;
$green3: #06c270;

$gray: #8b8d97;
$gray1: #c7c9d9;
$gray2: #a1a1a1;
$gray3: #888;
$gray4: #6c7a89;
$gray5: #f4f5fa;
$gray6: #e4e5ea;
$gray7: #f5f5f5;
$gray8: #555770;
$gray9: #4e4e4e;
$gray10: #eeeeee;
$gray11: #8f90a6;
$gray12: #e4e4eb;
$gray13: #f5f5f6;
$gray14: #fafafc;
$gray15: #ebebf0;
$gray16: #f2f2f5;
$gray17: #efefef;
$gray18: #f4f5f9;
$gray19: #d7def0;
$gray20: #555770;

$white: #ffffff;
$white2: #e6e6e6;

// fonts vars
$font-open-sans: "Open Sans", sans-serif;
$font-inter: "Inter", sans-serif;

//loader
#loader_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: 999;
	svg {
		width: 80px;
		height: 80px;
		margin: 20px;
		display: inline-block;
	}
}

html,
body,
#root {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

body {
	font-family: $font-open-sans;
	font-weight: 400;
	color: $black;
}

.not_found_wrapper {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	.not_found {
		background-color: $gray5;
		width: 100%;
		min-height: 100dvh;
		padding: 50px 40px 30px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		.header {
			margin: 0 0 auto;
			svg {
				width: 240px;
				height: auto;
			}
		}

		.content_wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 35px;

			.text {
				font-weight: 500;
				font-size: 32px;
				color: $blue4;
				margin-top: 55px;
				margin-bottom: 120px;
			}

			.button {
				font-weight: 700;
				font-size: 19px;
				color: white;
				text-transform: uppercase;
				background-color: $blue1;
				border-radius: 5px;
				padding: 20px 200px;
			}
		}

		.info {
			margin: 35px 0 0;
			.text {
				color: $gray20;
				text-align: center;
				a {
					display: inline;
					font-weight: 700;
					color: $gray20;
					text-decoration: underline;
				}
			}
		}
	}
}

.registration_wrapper .input_wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 0 0 20px;
	label {
		font: 12px/1.3 $font-open-sans;
		color: $black;
		span.star {
			color: $red;
		}
	}
	.input {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 15px 12px;
		border-radius: 5px;
		border: 1px solid $white2;
		input,
		select {
			width: 100%;
			font: 15px/20px $font-open-sans;
			color: $gray;
		}
		.hide_password {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 20px;
			cursor: pointer;
		}
	}
}

.registration_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	position: relative;
	padding: 20px;
	background: $gray5;
	img.background_image {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 1400px;
		padding: 0 0 20px;
		margin: 0 0 50px;
		border-bottom: 1px solid $gray6;
		z-index: 2;
	}
	main {
		width: 100%;
		height: 100%;
		.login_wrapper {
			.login_header {
				text-align: center;
				h1 {
					margin: 0 0 15px;
					font: 700 48px/1.3 $font-open-sans;
					color: $black;
					span {
						color: $blue;
					}
				}
				.subheader {
					margin: 0;
					font: 14px/1.5 $font-open-sans;
					color: $gray9;
				}
			}
			.login_content_wrapper {
				display: flex;
				justify-content: center;
				margin: 50px 0;
				.login_content {
					max-width: 535px;
					background: white;
					padding: 30px;
					border-radius: 8px;
					h2 {
						font: 700 28px/1.3 $font-open-sans;
						color: $black;
						margin: 0 0 8px;
					}
					.text {
						font: 14px/1.3 $font-open-sans;
						color: $gray;
						margin: 0 0 25px;
					}
					form {
						.error {
							font: 14px/1.3 $font-open-sans;
							color: $red2;
							margin: 0 0 15px;
						}
						.submit {
							width: 100%;
							text-align: center;
							padding: 15px;
							font: 700 16px/1.3 $font-open-sans;
							color: white;
							background: $blue1;
							box-shadow: 0px 5px 10px 0px rgba(11, 11, 11, 0.08);
							margin: 0 0 25px;
							border-radius: 5px;
						}
					}
					.sign_up {
						text-align: center;
						font: 14px/1.3 $font-open-sans;
						color: $black;
						a {
							display: inline;
							color: $blue;
						}
					}
				}
			}
		}
		h1 {
			margin: 0 0 40px;
			font: 700 48px/1.3 $font-open-sans;
			color: $black;
			text-align: center;
			span {
				color: $blue;
			}
		}
		.progress_bar {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 50px;
			.reg_step {
				align-items: center;
				background: white;
				border-radius: 100%;
				color: $gray1;
				display: flex;
				font-size: 16px;
				font-weight: 500;
				height: 34px;
				justify-content: center;
				line-height: 18px;
				margin: 0 20px;
				width: 34px;
				&.active {
					background: $blue1;
					color: white;
				}
			}
			.progress_line {
				border: 0;
				border-top: 2px solid white;
				height: 1px;
				padding: 0;
				width: 100px;
				&.active {
					border-top: 2px solid $blue1;
				}
			}
		}
		.form_wrapper {
			display: flex;
			flex-direction: column;
			background: white;
			padding: 35px 20px;
			border-radius: 8px;
			max-width: 885px;
			width: 100%;
			margin: 0 auto;
			.header_wrapper {
				display: flex;
				align-items: center;
				gap: 10px;
				margin: 0 0 40px;
				h2 {
					font: 700 24px/1.3 $font-open-sans;
					color: $black;
				}
			}
			.subheader {
				margin: -30px 0 40px;
				font: 14px/1.3 $font-open-sans;
				color: $gray11;
			}
			.drop_zone_wrapper {
				margin: 0 auto 40px;
				.image {
					.upload-placeholder {
						width: 180px;
					}
					.uploaded-preview {
						height: 180px;
						object-fit: cover;
						width: 180px;
					}
				}
			}
			.skip_step {
				text-align: center;
				margin: 0;
				font: 14px/1.3 $font-open-sans;
				color: $black;
			}
			form {
				display: flex;
				flex-wrap: wrap;
				column-gap: 40px;
				margin: 0 0 35px;
				.input_wrapper {
					flex-basis: 34%;
					flex-grow: 1;
					&.has_error {
						.input {
							border: 1px solid $red2;
						}
					}
					.error_wrapper {
						font: 14px/1.3 $font-open-sans;
						color: $red2;
					}
				}
			}
			.submit {
				max-width: 450px;
				width: 100%;
				align-self: center;
				text-align: center;
				padding: 15px;
				font: 700 16px/1.3 $font-open-sans;
				color: white;
				background: $blue1;
				box-shadow: 0px 5px 10px 0px rgba(11, 11, 11, 0.08);
				margin: 0 0 25px;
				border-radius: 5px;
			}
		}
		.pending_wrapper {
			height: 100%;
			width: 100%;
			justify-content: center;
			overflow-y: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
			.pending {
				display: flex;
				flex-direction: column;
				align-items: center;
				max-width: fit-content;
				background: white;
				padding: 35px 20px;
				border-radius: 8px;
				max-width: 460px;
				width: 100%;
				margin: 0 auto;
				text-align: center;
				img {
					width: 52px;
					height: auto;
					margin: 0 0 10px;
				}
				span {
					margin: 0 0 8px;
					font: 700 26px/1.3 $font-open-sans;
					color: $black;
				}
				p {
					font: 14px/1.3 $font-open-sans;
					color: $gray;
				}
			}
			.contact_wrapper {
				display: flex;
				align-items: flex-start;
				gap: 12px;
				max-width: 460px;
				width: 100%;
				margin: 25px auto 0;
				border-radius: 14px;
				background: rgba(34, 52, 143, 0.05);
				padding: 15px;
				p {
					font: 14px/1.3 $font-open-sans;
					color: $blue3;
					a {
						display: inline;
						color: $blue1;
						text-decoration: underline;
					}
				}
			}
		}
		footer {
			margin: 25px 0 0;
			text-align: center;
			p {
				margin: 0 0 2px;
				font: 14px/1.5 $font-open-sans;
				color: $gray;
				a {
					display: inline;
					font: 700 14px/1.5 $font-open-sans;
					color: $blue1;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
	}
	.scrollable_wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 1400px;
		width: 100%;
		height: 100%;
		z-index: 2;
		overflow-y: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}

@media (max-width: 1440px) {
	.registration_wrapper {
		header {
			width: 100%;
		}
		.scrollable_wrapper {
			width: 100%;
		}
	}
}
@media (max-width: 1000px) {
	.registration_wrapper {
		padding: 20px 20px 5px;
		header {
			margin: 0 0 25px;
		}
		main {
			.login_wrapper .login_header {
				h1 {
					font: 700 36px/1.3 $font-open-sans;
				}
				.subheader {
					font: 16px/1.3 $font-open-sans;
				}
			}
			.form_wrapper .header_wrapper {
				h2 {
					font: 700 20px/1.3 $font-open-sans;
				}
			}
			h1 {
				margin: 0 0 15px;
				font: 700 36px/1.3 $font-open-sans;
			}
			.progress_bar {
				margin: 0 0 25px;
				.reg_step {
					margin: 0 5px;
				}
				.progress_line {
					width: 10%;
				}
			}
		}
	}
	.not_found_wrapper .not_found {
		padding: 15px;
		justify-content: space-between;
		.header {
			margin: 0 0 35px;
			svg {
				width: 240px;
				height: auto;
			}
		}
		.content_wrap {
			svg {
				max-width: 100%;
				height: auto;
			}
			.text {
				margin: 35px 0 50px;
			}
			.button {
				max-width: 100%;
				padding: 20px;
			}
		}
	}
}
@media (max-width: 640px) {
	.registration_wrapper main .form_wrapper form {
		.input_wrapper {
			flex-basis: 100%;
		}
	}
}
